import PropTypes from 'prop-types';
import CorusImage from '../../CorusImage/CorusImage';
import addTabnabbing from '../../../utils/addTabnabbing';
import { getImageHeightAspectRatio } from '../../../utils/utility';

export default function Promo(props) {
	const { promo } = props;

	if (!promo.enable) return null;

	const content = addTabnabbing(promo.content);
	const title = promo?.title;
	const disclaimer = promo?.disclaimer;
	const imageWidth = 551;
	const imageHeight = getImageHeightAspectRatio(promo.image.mediaDetails.width, promo.image.mediaDetails.height, imageWidth);
	const imageURL = promo.image?.sourceUrl;

	return (
		<div className="promo">
			<div className="promo-image-wrapper">
				{ imageURL && (
					<CorusImage
						src={imageURL}
						alt={title}
						title={title}
						width={imageWidth}
						height={imageHeight}
					/>
				)}
			</div>
			<div className="promo-details">
				<h2 className="promo-details-title">{title}</h2>
				<div className="promo-details-content">
					{content}
				</div>
				{disclaimer && (
					<div className="promo-details-disclaimer">{disclaimer}</div>
				)}
			</div>
		</div>
	);
}

Promo.propTypes = {
	promo: PropTypes.shape({
		enable: PropTypes.bool.isRequired,
		title: PropTypes.string.isRequired,
		image: PropTypes.object.isRequired,
		content: PropTypes.string.isRequired,
		disclaimer: PropTypes.string,
	}).isRequired,
};
