import PropTypes from 'prop-types';
import Announcer from '../../Announcer/Announcer';

export default function NewsletterError({ errorMessage }) {
	return (
		<>
			{errorMessage && (
				<div className="newsletter-block__error">
					<div>
						<div className="error-icon"><span>!</span></div>
					</div>
					<div>
						<p>{errorMessage}</p>
					</div>
					<Announcer message={errorMessage} />
				</div>
			)}
		</>
	);
}

NewsletterError.propTypes = {
	errorMessage: PropTypes.string.isRequired,
};
