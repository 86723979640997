import PropTypes from 'prop-types';
import PostBlock from './PostBlock';
import PostBlockGridWithAds from './PostBlockGridWithAds';
import globalValues from '../../static-data/global-values.json';

const { cesDataAdsMagniteEnabled } = globalValues?.data?.corusAdsSettings;

export default function PostBlockGrid(props) {
	const {
		posts, title, isLoader, className, showPostType, adConfig, passQueryVar,
	} = props;

	const { loadGoogleAds, loadMagniteAds } = adConfig;

	const postBlocks = [{ postId: 'loader1' }, { postId: 'loader2' }, { postId: 'loader3' }];

	const displayPosts = () => {
		if (loadGoogleAds || (cesDataAdsMagniteEnabled && loadMagniteAds)) {
			return (
				<PostBlockGridWithAds
					posts={posts}
					className={`post-block-grid ${className}`}
					isLoader={isLoader}
					showPostType={showPostType}
					adConfig={adConfig}
					passQueryVar={passQueryVar}
				/>
			);
		}

		return (
			<div className={`post-block-grid ${className}`}>
				{isLoader
					? postBlocks.map((post) => (<PostBlock key={post.postId} post={post} isLoader showPostType={showPostType} />))
					: posts.map((post) => (<PostBlock key={post.postId} post={post} passQueryVar={passQueryVar} showPostType={showPostType} />))}
			</div>
		);
	};

	return (
		<>
			{(title) && <h2 className="post-block-grid-title">{title}</h2>}
			{displayPosts()}
		</>
	);
}

PostBlockGrid.propTypes = {
	posts: PropTypes.array.isRequired,
	title: PropTypes.string,
	isLoader: PropTypes.bool,
	className: PropTypes.string,
	showPostType: PropTypes.bool,
	adConfig: PropTypes.shape({
		loadGoogleAds: PropTypes.bool,
		loadMagniteAds: PropTypes.bool,
	}),
	passQueryVar: PropTypes.bool,
};

PostBlockGrid.defaultProps = {
	title: null,
	isLoader: false,
	className: '',
	showPostType: false,
	adConfig: {
		loadGoogleAds: false,
		loadMagniteAds: false,
	},
	passQueryVar: false,
};
