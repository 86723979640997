import PropTypes from 'prop-types';
import HtmlWrapper from '../../Text/HtmlWrapper';

export default function NewsletterTerms({ termsMessage }) {
	return (
		<>
			{termsMessage && (
				<div className="newsletter-block__terms">
					<HtmlWrapper html={`<p>${termsMessage}</p>`} />
				</div>
			)}
		</>
	);
}

NewsletterTerms.propTypes = {
	termsMessage: PropTypes.string.isRequired,
};
