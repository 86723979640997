// eslint-disable-next-line import/prefer-default-export
export const AdSizes = {
	BIG_BOX: [[300, 250]],
	DOUBLE_BIG_BOX: [[300, 600]],
	BIG_BOX_COMBO: [[300, 600], [300, 250]],
	BADGE: [[300, 125]],
	LEADERBOARD: [[728, 90]],
	LEADERBOARD_BIGBOX_COMBO: [[300, 250], [728, 90]],
	LARGE_LEADERBOARD: [[970, 90]],
	BILLBOARD: [[970, 250]],
	LEADERBOARD_COMBO: [[970, 250], [728, 90]],
	ALL_LEADERBOARDS: [[970, 250], [728, 90], [970, 90]],
	MOBILE_LEADERBOARD: [[320, 50]],
	MOBILE_LEADERBOARD_COMBO: [[300, 250], [320, 50]],
	SPONSOR_LOGO: [[160, 60]],
	WALLPAPER: [[1, 1]],
	CATFISH: [[1, 1]],
	HIDDEN: [],
};
