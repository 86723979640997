import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import OutbrainScript from './scripts/OutbrainScript';

/**
 * @summary Simple dynamic wrapper around react-outbrain-widget's `OutbrainWidget` component.
 * Ensures this component renders only on the client.
 * Through some investigation, the Outbrain widget needs to render on the client, otherwise
 * we will see client/server initial HTML mismatch warnings, and Outbrain may not fully render itself
 * inside the inner `<div class="OUTBRAIN">`.
 * You can forward any of the props for `react-outbrain-widget` to this wrapper component.
 * 
 */
const ClientOnlyOutbrainWidget = dynamic(
	// Async import `react-outbrain-widget` and select the named `OutbrainWidget` export.
	// This is the dynamic import equivalent of `import { OutbrainWidget } from 'react-outbrain-widget';`.
	async () => (await import('react-outbrain-widget')).OutbrainWidget,
	{ ssr: false },
);

/**
 * @summary Renders an Outbrain widget.
 * Wraps Outbrain's React widget with a `<div class="outbrain-widget">...</div>` so we have a similar structure
 * across Lifestyle brand sites.
 * Also embeds the Outbrain widget script in a `next/script` via `OutbrainScript`.
 * @param {object} props Props
 * @param {string} props.src URL for the current page or post.
 * @param {string} props.widgetId Widget ID given by Outbrain.
 * @returns {JSX.Element}
 *
 * Please note that the Outbrain widget has the following CSS structure:
 * ```scss
 * div.outbrain-widget {
 *   div.OB-REACT-WRAPPER {
 *     div.OUTBRAIN { ... }
 *   }
 * }
 * ```
 */
export default function OutbrainAd({
	src,
	widgetId,
}) {
	return (
		<>
			<OutbrainScript />
			<div className="outbrain-widget">
				<ClientOnlyOutbrainWidget dataSrc={src} dataWidgetId={widgetId} />
			</div>
		</>
	);
}

OutbrainAd.propTypes = {
	src: PropTypes.string.isRequired,
	widgetId: PropTypes.string.isRequired,
};
