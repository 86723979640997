import Link from 'next/link';
import PropTypes from 'prop-types';
import { addTrailingSlash } from '../../utils/utility';

/**
 * Return authors bylne with list of authors.
 * accepts:
 *  Authors as an array (required)
 *   single Author properties
 *   - author.title (required)
 *   - author.link (optional)
 *  Prefix for byline (optional)
 */
export default function AuthorsByline(props) {
	const limit = 3;
	const { authors: authorsFullList, prefix } = props;
	const authors = authorsFullList.slice(0, limit);

	const authorsList = authors.map((author, index) => (
		<span key={author.title}>
			{
				author.link
					? (
						<Link href={addTrailingSlash(author.link)}>
							{author.title}
						</Link>
					)
					: (author.title)
			}
			{(index < authors.length - 2) ? ', ' : null}
			{(authors.length > 1) && (authors.length - 2 === index) ? ' and ' : null}
		</span>
	));

	return (
		<div className="author-byline">
			{`${prefix} ` }
			{authorsList}
		</div>
	);
}

AuthorsByline.propTypes = {
	authors: PropTypes.array.isRequired,
	prefix: PropTypes.string,
};

AuthorsByline.defaultProps = {
	prefix: '',
};
