import { useState, useEffect } from 'react';

/**
 * @summary Get the current width of the window or the document.
 * Returns `undefined` if this is being run on the server.
 * @returns {number | undefined}
 */
const getWidth = () => {
	if (typeof window !== 'undefined') {
		return window.innerWidth
		|| document.documentElement.clientWidth
		|| document.body.clientWidth;
	}
	return undefined;
};

/**
 * @summary Use the current width of the window or document root.
 * @returns {number} The current width of the window or document root.
 */
export default function useCurrentWidth() {
	// save current window width in the state object
	const [width, setWidth] = useState(getWidth());

	useEffect(() => {
		const resizeListener = () => {
			setWidth(getWidth());
		};
		// set resize listener
		window.addEventListener('resize', resizeListener);

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return width;
}
