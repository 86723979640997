import Head from 'next/head';
import PropTypes from 'prop-types';

export default function SeoGoogleSitelinkSearchBox({ siteUrl }) {
	return (
		<Head>
			<script
				type="application/ld+json"
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `
					{
						"@context": "https://schema.org",
						"@type": "WebSite",
						"url": "${siteUrl}",
						"potentialAction": {
							"@type": "SearchAction",
							"target": {
								"@type": "EntryPoint",
								"urlTemplate": "${siteUrl}search/{search_string}/"
							},
							"query-input": "required name=search_string"
						}
					}`,
				}}
			/>
		</Head>
	);
}

SeoGoogleSitelinkSearchBox.propTypes = {
	siteUrl: PropTypes.string.isRequired,
};
