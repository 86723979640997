import PropTypes from 'prop-types';
import NewsletterForm from './NewsletterForm';
import globalValues from '../../static-data/global-values.json';

export default function Newsletter({ size = 'fullWidth', type = 'homepage' }) {
	const { newsletterSettings } = globalValues?.data?.newsletterSettings;
	const { enableNewsletter, enableNewsletterRecipes } = newsletterSettings;
	const isNewsLetterOn = type === 'homepage' ? enableNewsletter ?? false : enableNewsletterRecipes ?? false;
	const newsletterClasses = `newsletter ${size}`;

	return (
		isNewsLetterOn && (
			<div className={newsletterClasses} id="newsletter">
				<div className="newsletter-block">
					<div className="newsletter-block__form-wrapper">
						<NewsletterForm details={newsletterSettings} />
					</div>
				</div>
			</div>
		)
	);
}

// prop validation for Newsletter component
Newsletter.propTypes = {
	size: PropTypes.string,
	type: PropTypes.string,
};

Newsletter.defaultProps = {
	size: 'fullWidth',
	type: 'homepage',
};
