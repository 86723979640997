import { useContext } from 'react';
import { useRouter } from 'next/router';

// Contexts
import AppContext from '../context/AppContext';

export default function useCurrentUrl() {
	const { hostUrl } = useContext(AppContext);

	const router = useRouter();

	return new URL(router.asPath, hostUrl);
}
