import PropTypes from 'prop-types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { decode } from 'html-entities';
import Rating from '../../widgets/Rating/Rating';
import AuthorsByline from '../../widgets/AuthorsByline/AuthorsByline';
import * as utility from '../../utils/utility';

import PlayIcon from '../../inline-images/play-icon.svg';
import FallbackImage from '../../inline-images/post-fallback-image.jpg';

/**
 * @summary Renders a PostBlock, which may be used for Posts or Recipes.
 * @param {object} props
 * @param {any} props.post Post to display.
 * @param {boolean} [props.isLoader] Whether or not the query being used to fetch the data for this component is running.
 * @param {boolean} [props.passQueryVar] Should this block pass URL query parameters?
 * @param {boolean} [props.showPostType] Whether or not to show the post type for this block.
 * @returns {JSX.Element}
 */
export default function PostBlock(props) {
	const {
		post, isLoader, passQueryVar, showPostType,
	} = props;

	const postTypeTags = {
		ce_recipe: 'Recipe',
		ce_author: 'Chef / Host',
		post: 'Article',
		tag: 'Show',
	};

	const postTypeTag = postTypeTags[post.postType];

	const [queryParam, setQueryParam] = useState(false);
	const blockClassName = `post-block ${isLoader ? 'loader' : post.postType}`;

	useEffect(() => {
		const url = new URL(window.location.href);
		const { searchParams } = url;

		if (searchParams.has('page') || searchParams.has('filter')) {
			searchParams.delete('page');
			searchParams.delete('filter');
		}

		setQueryParam(searchParams.toString());
	}, []);

	const tagLink = post.featuredTag?.uri ? `${post.featuredTag.uri}${passQueryVar && queryParam ? `?${queryParam}` : ''}` : '';

	const featuredTagLinkTitle = post?.featuredTag?.name ? (
		`Explore more content tagged with ${decode(post.featuredTag.name)}.`
	) : (
		'Explore more content about this tag.'
	);

	const featuredTagLinkLabel = post?.featuredTag?.name ? decode(post.featuredTag.name) : 'Tag';

	const imgSource = !post?.featuredImage || post?.featuredImage.length > 200 ? FallbackImage.src : post?.featuredImage;

	return (
		<div className={blockClassName}>
			{postTypeTag && showPostType && (
				<div className="post-block__type">{postTypeTag}</div>
			)}
			<div className="post-block__image-container">
				{post.is_video
					&& (
						<div className="post-block__image-container-overlay">
							<PlayIcon />
						</div>
					)}
				{isLoader ? (
					<img
						width="167"
						height="167"
						alt=""
						src=""
					/>
				) : (
					(
						<Link href={post?.permalink ?? ''} className="post-block__image-link">

							<img
								width="167"
								height="167"
								alt={post.title}
								title={post.title}
								src={imgSource}
							/>

						</Link>
					)
				)}
			</div>
			<div className="post-block__details">
				<div className="post-block__tag">
					{post.featuredTag?.uri
						? (
							(
								<Link
									href={tagLink}
									aria-label={featuredTagLinkLabel}
									title={featuredTagLinkTitle}
								>

									{featuredTagLinkLabel}

								</Link>
							)
						)
						: null}
				</div>
				{(post.title !== '' && !isLoader) ? (
					<h2 className="post-block__title">
						<Link href={post.permalink ?? ''}>
							{post.title}
						</Link>
					</h2>
				) : (
					<div className="post-block__title" />
				)}

				{post.postType === 'ce_recipe' ? (
					<div className="post-block__recipe">
						<div className="post-block__rating">
							<Rating rating={post.reciperating} />
						</div>
						<div className="post-block__cooking">
							<div className="post-block__cook-time">{utility.formatMinutes(post.recipetime)}</div>
							<div className="post-block__serves">{post.serving}</div>
						</div>
					</div>
				) : (
					<div className="post-block__article">
						<div className="post-block__author">
							{post.authors && <AuthorsByline authors={post.authors} />}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

PostBlock.propTypes = {
	post: PropTypes.object.isRequired,
	isLoader: PropTypes.bool,
	passQueryVar: PropTypes.bool,
	showPostType: PropTypes.bool,
};

PostBlock.defaultProps = {
	isLoader: false,
	passQueryVar: false,
	showPostType: false,
};
