import Script from 'next/script';

export default function IndexExchangeScript() {
	return (
		<Script
			id="ce_ads_web_magnite_js-js"
			src="https://static.freeskreen.com/ba/31/freeskreen.min.js?ver=3.3.2"
		/>
	);
}
