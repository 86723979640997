import { useState, useEffect, useCallback } from 'react';
import Router from 'next/router';

/**
 * @summary Get value session storage data using `key` if available.
 * Only reads value; does NOT create it if not exists.
 * 
 * @param {string} key Key of data in session storage.
 * @param {boolean} prependSiteURL Should this hook prepend the site URL to the returned value?
 * @param {string} siteURL Site URL to prepend to value.
 */
export default function useReadSessionStorage(key, prependSiteURL = false, siteURL = '') {
	const [value, setValue] = useState('');

	const handleBeforeHistoryChange = useCallback(() => {
		const maybeSessionValue = window.sessionStorage.getItem(key);

		if (maybeSessionValue !== null) {
			setValue(prependSiteURL ? siteURL + maybeSessionValue : maybeSessionValue);
		}
	}, [key, prependSiteURL, siteURL]);

	useEffect(() => {
		handleBeforeHistoryChange();

		Router.events.on('routeChangeComplete', handleBeforeHistoryChange);

		return () => {
			Router.events.off('routeChangeComplete', handleBeforeHistoryChange);
		};
	}, [handleBeforeHistoryChange]);

	return value;
}
