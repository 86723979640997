import PropTypes from 'prop-types';
import Announcer from '../../Announcer/Announcer';

export default function NewsletterSuccess({ successMessage }) {
	return (
		<>
			{successMessage && (
				<div className="newsletter-block__success">
					<p>{successMessage}</p>
					<Announcer message={successMessage} />
				</div>
			)}
		</>
	);
}

NewsletterSuccess.propTypes = {
	successMessage: PropTypes.string.isRequired,
};
