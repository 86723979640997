import { useEffect } from 'react';
import PropTypes from 'prop-types';
import MagniteScript from './scripts/MagniteScript';

export default function MagniteAd(props) {
	const { wrapperClass } = props;

	const setMutationObserver = (elementId) => {
		const observer = new MutationObserver((mutations, obs) => {
			const adDiv = document.getElementById(elementId);
			if (adDiv) {
				adDiv.style.display = 'block';
				obs.disconnect();
			}
		});

		observer.observe(document, {
			childList: true,
			subtree: true,
		});
	};

	useEffect(() => {
		const adId = process.env.NEXT_PUBLIC_MAGNITE_DIV_ID;

		setMutationObserver(adId);

		return (() => {
			const adDiv = document.getElementById(adId);
			if (adDiv) {
				adDiv.remove();
			}
		});
	}, []);

	return (
		<>
			<MagniteScript />
			<div id="magnite" className={`${wrapperClass}`} />
		</>
	);
}

MagniteAd.propTypes = {
	wrapperClass: PropTypes.string,
};

MagniteAd.defaultProps = {
	wrapperClass: '',
};
