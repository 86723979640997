import Script from 'next/script';

const OUTBRAIN_WIDGET_SCRIPT_SOURCE = 'https://widgets.outbrain.com/outbrain.js';

/**
 * @summary Renders the `script` tag for the Outbrain widget script,
 * using Next.js's `next/script` component.
 * @returns {JSX.Element}
 */
export default function OutbrainScript() {
	return (
		<Script
			id="outbrain-widget-script"
			src={OUTBRAIN_WIDGET_SCRIPT_SOURCE}
			strategy="beforeInteractive"
			async
		/>
	);
}
